import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { unpreferShift } from '../actions/shift-actions'
import ShiftList from '../components/ShiftList'
import Shift from '../components/Shift'
import ShiftDetailsSmall from '../components/ShiftDetailsSmall'

const PreferredShiftsList = ({ shifts, actions }) => (
  <ShiftList>
    {shifts.map(shift => (
      <Shift
        key={shift.id}
        shift={shift}
        actions={actions}
      >
        <ShiftDetailsSmall shift={shift} />
      </Shift>
    ))}
  </ShiftList>
)

const mapStateToProps = state => ({
  shifts: state.shifts.filter(shift => shift.preferred)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    unpreferShift
  }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferredShiftsList)
