/* global I18n */

import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import ShiftRequirementDetails from './ShiftRequirementDetails'

const i18nOptions = {
  scope: 'shift_selector.shift.mtp_requirements'
}

const ShiftDetailsOneLine = ({ shift }) => {
  const { name,
    startTime,
    endTime,
    schedule,
    availableSpots,
    duration,
    memberTypePropertyRequirements
  } = shift
  const shiftDate = startTime.format('dddd [d.] Do MMMM YYYY')
  const shiftTime = `${startTime.format('HH:mm')}  ${endTime.format('HH:mm')}`

  const requirementDetailsPopover = (
    <Popover
      id="popover-positioned-left"
      bsClass="shift-selector-popover popover"
      title={I18n.t('requirements_header', i18nOptions)}
    >
      <ShiftRequirementDetails requirements={memberTypePropertyRequirements} />
    </Popover>
  )

  return (
    <div className="shift-details shift-details-medium">
      <div className="shift-date">
        <p><strong>{shiftDate}</strong></p>
      </div>
      <div className="shift-time">
        <p><strong>{shiftTime}</strong></p>
      </div>
      <div className="shift-duration">
        <p>{I18n.t('shift_duration', { scope: 'shift_selector', count: duration })}</p>
      </div>
      <div className="shift-availability">
        <p>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="left"
            overlay={requirementDetailsPopover}
          >
            <span>
              <strong>{I18n.t('available_spots', i18nOptions)}: {availableSpots}</strong>
              &nbsp;
              <i className="fa fa-info-circle" />
            </span>
          </OverlayTrigger>
        </p>
      </div>
    </div>
  )
}

export default ShiftDetailsOneLine
