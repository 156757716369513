/* global I18n */

const i18nOptions = {
  scope: "shift_selector.requirement_selector"
}

const RequirementSelector = ({
  shiftId,
  requirements,
  noRequirementOption,
  onRequirementSelect
}) => {
  const fields = requirements.map((requirement, index) => {
    return (
      <li
        className="list-group-item"
        key={index}
      >
        <div
          role="presentation"
          onClick={() => onRequirementSelect(shiftId, requirement.id)}
        >
          {requirement.name} {requirement.requiredPrettyValue}
        </div>
      </li>
    )
  })

  return (
    <ul className="list-group list-group-hover">
      { noRequirementOption ?
        (
          <li className="list-group-item" key="no-requirement">
            <div
              role="presentation"
              onClick={() => onRequirementSelect(shiftId)}
            >
              {I18n.t('no_requirement', i18nOptions)}
            </div>
          </li>
        ) : null }

      {fields}
    </ul>
  )
}

RequirementSelector.defaultProps = {
  noRequirementOption: false,
  requirements: []
}

RequirementSelector.propTypes = {
  shiftId: PropTypes.number.isRequired,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      requiredPrettyValue: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  noRequirementOption: PropTypes.bool,
  onRequirementSelect: PropTypes.func.isRequired
}

export default RequirementSelector
