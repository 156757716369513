import {
  RECEIVE_CURRENT_MEMBER
} from '../constants/action-types'

const defaultState = {
  maxShiftHours: null,
  minShiftHours: null
}

const currentMemberReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_CURRENT_MEMBER:
      return {
        ...state,
        ...action.currentMember
      }
    default:
      return state
  }
}

export default currentMemberReducer
