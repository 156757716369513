import { combineReducers } from 'redux'
import shifts from './shiftsReducer'
import errors from './errorsReducer'
import filters from './filtersReducer'
import ui from './uiReducer'
import currentMember from './currentMemberReducer'


const rootReducer = combineReducers({
  shifts,
  errors,
  filters,
  ui,
  currentMember
})

export default rootReducer
