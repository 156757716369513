import fetch from 'isomorphic-fetch'

class Api {
  constructor(resource) {
    this.resource = resource
  }

  resourcePath(id) {
    return `${this.resource}/${id}`
  }

  fetch(id) {
    return this.get({
      pathPrefix: this.resourcePath(id)
    })
  }

  fetchAll() {
    return this.get({
      pathPrefix: this.resource
    })
  }

  update(id, { body }) {
    return this.patch({
      pathPrefix: this.resourcePath(id),
      body
    })
  }

  create({ body }) {
    return this.post({
      pathPrefix: this.resource,
      body
    })
  }

  get(options = {}) {
    return this.request({
      method: 'GET',
      ...options
    })
  }

  post(options = {}) {
    return this.request({
      method: 'POST',
      ...options
    })
  }

  patch(options = {}) {
    return this.request({
      method: 'PATCH',
      ...options
    })
  }

  put(options = {}) {
    return this.request({
      method: 'PUT',
      ...options
    })
  }

  delete(options = {}) {
    return this.request({
      method: 'DELETE',
      ...options
    })
  }

  request({ pathPrefix, pathSuffix, body, method, headers = {} }) {
    let path = '/api'
    if (pathPrefix !== undefined) { path += `/${pathPrefix}` }
    if (pathSuffix !== undefined) { path += `/${pathSuffix}` }
    path += '?key_case=camel_lower'

    if (body !== undefined) {
      body = JSON.stringify(body)
    }

    Object.assign(headers, {
      'Accept': 'application/json', // eslint-disable-line quote-props
      'Content-Type': 'application/json'
    })

    if (method !== 'GET') {
      headers['X-CSRF-Token'] = Api.csrfToken()
    }

    return fetch(path, {
      credentials: 'same-origin',
      method,
      headers,
      body
    })
  }

  static csrfToken() {
    const meta = document.querySelector('meta[name=csrf-token]')

    if (meta) {
      return meta.content
    }
  }
}

export default Api
