/* global I18n */

import PreferShiftButton from '../containers/PreferShiftButton'
import SelectShiftButton from '../containers/SelectShiftButton'

import '../styles/shift.scss'

const Shift = ({
  children,
  shift
}) => {
  const { selectionType } = shift
  const actionButton = selectionType === 'direct' ?
    <SelectShiftButton shift={shift} /> : <PreferShiftButton shift={shift} />

  return (
    <div className="shift">
      {children}
      <div className="shift-actions">
        {actionButton}
      </div>
    </div>
  )
}

export default Shift
