import * as types from '../constants/action-types'

export const setCurrentTab = tab => ({ type: types.SET_CURRENT_TAB, tab })
export const setCurrentView = view => ({ type: types.SET_CURRENT_VIEW, view })
export const selectedRequirement = () => ({ type: types.SELECTED_REQUIREMENT })

export const selectingRequirement = (shiftId, requirements, noRequirementOption = false) => ({
  type: types.SELECTING_REQUIREMENT,
  shiftId,
  requirements,
  noRequirementOption
})
