import { createSelector } from 'reselect'
import getUnselectedShifts from './getUnselectedShifts'

const getView = state => state.ui.currentView

const getShiftGroupKey = (shift, view) => {
  switch (view) {
    case 'list-grouped-by-date':
      return shift.startTime.clone().startOf('day').toISOString()
    case 'list-grouped-by-schedule':
      return shift.schedule.name
    default:
      throw new Error(`Unknown view: ${view}`)
  }
}

const getGroupedShifts = createSelector(
  getUnselectedShifts, getView,
  (shifts, view) => {
    const shiftGroups = shifts.reduce((groups, shift) => {
      const groupKey = getShiftGroupKey(shift, view)

      groups[groupKey] = groups[groupKey] || []
      groups[groupKey].push(shift)

      return groups
    }, {})

    return Object.entries(shiftGroups).sort(([groupKeyA, _A], [groupKeyB, _B]) => (
      groupKeyA.localeCompare(groupKeyB)
    )).reduce((groups, [key, group]) => {
      groups[key] = group.sort((shiftA, shiftB) => (
        shiftA.startTime - shiftB.startTime
      ))

      return groups
    }, {})
  }
)

export default getGroupedShifts
