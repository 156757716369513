import React from 'react'
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap'

const ShiftActionButton = ({
  handleClick,
  buttonStyle,
  buttonText,
  active,
  inactiveReason,
  error,
  isLoading,
  selected
}) => {
  let buttonType

  if (error) {
    buttonText = <i className="fa fa-times"></i>
  } else if (isLoading) {
    buttonText = <i className="fa fa-spinner fa-spin"></i>
  } else if (selected) {
    buttonText = <i className="fa fa-times"></i>
  }

  if(error) {
    buttonType = 'danger'
  } else if (selected) {
    buttonType = 'warning'
  } else {
    buttonType = 'primary'
  }

  if (active) {
    return (
      <Button
        onClick={handleClick}
        bsStyle={buttonType}
        bsSize="sm">

        {buttonText}
      </Button>
    )
  } else {
    const tooltip = (
      <Tooltip id="tooltip">
        {inactiveReason}
      </Tooltip>
    )

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <Button
          bsStyle={buttonType}
          bsSize="sm"
          className="disabled">

          {buttonText}
        </Button>
      </OverlayTrigger>
    )
  }
}

export default ShiftActionButton
