import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { checkRequirementsAndSelectShift, deselectShift } from '../actions/shift-actions'
import ShiftActionButton from '../components/ShiftActionButton'

const i18nOptions = {
  scope: "shift_selector.shift"
}

class SelectShiftButton extends Component {
  handleClick = () => {
    const { shift, selectShift, deselectShift } = this.props
    const { id, selected, error } = shift

    if( error) {
    } else if (selected) {
      deselectShift(id)
    } else {
      selectShift(id)
    }
  }

  render() {
    const { shift } = this.props
    const { id, selected, eligible, ineligibilityReason, isLoading, error } = shift

    return (
      <ShiftActionButton
        handleClick={this.handleClick}
        buttonText={I18n.t('actions.select_shift', i18nOptions)}
        active={eligible || selected}
        inactiveReason={ineligibilityReason}
        error={error}
        isLoading={isLoading}
        selected={selected}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  selectShift: (id) => dispatch(checkRequirementsAndSelectShift(id)),
  deselectShift: (id) => dispatch(deselectShift(id))
})

export default connect(
  null,
  mapDispatchToProps
)(SelectShiftButton)
