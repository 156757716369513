/* global I18n */

import SelectedShiftsBadge from '../containers/SelectedShiftsBadge'
import PreferredShiftsBadge from '../containers/PreferredShiftsBadge'
import SelectedShiftsList from '../containers/SelectedShiftsList'
import PreferredShiftsList from '../containers/PreferredShiftsList'

const i18nOptions = {
  scope: 'shift_selector.sidebar'
}

const Sidebar = ({ minShiftHours, maxShiftHours }) => {
  let hourDescription
  if (minShiftHours && maxShiftHours) {
    I18n.t('min_max_hours_description', { ...i18nOptions, min_hours: minShiftHours, max_hours: maxShiftHours })
  } else if (minShiftHours) {
    I18n.t('min_hours_description', { ...i18nOptions, min_hours: minShiftHours })
  }

  return (
    <div className="ibox schedule-app-sidebar">
      <div className="ibox-title">
        <h5>{I18n.t('sidebar_header', i18nOptions)}</h5>
        <div className="ibox-tools">
          <small>
            {hourDescription}
          </small>
        </div>
      </div>
      <div className="ibox-content">
        <h5>
          {I18n.t('selected_shifts_subheader', i18nOptions)}
          <div className="pull-right">
            <SelectedShiftsBadge />
          </div>
        </h5>
        <SelectedShiftsList />
      </div>
      <div className="ibox-content">
        <h5>
          {I18n.t('preferred_shifts_subheader', i18nOptions)}
          <div className="pull-right">
            <PreferredShiftsBadge />
          </div>
        </h5>
        <PreferredShiftsList />
      </div>
    </div>
  )
}

export default Sidebar
