/* global I18n */

import React, { Component } from 'react'

const i18nOptions = {
  scope: 'components.shift_report_form'
}

class MemberTypePropertyFilterSelect extends Component {
  state = {
    selectedId: this.props.attributes.memberTypePropertyId,
    filterValue: this.props.attributes.value
  }

  getDefaultFilterValueForMemberTypeProperty = (memberTypeProperty) => {
    switch (memberTypeProperty.type) {
      case 'MemberTypeProperties::CustomCheckbox':
        return 't'
      case 'MemberTypeProperties::CustomSelect':
        return Object.keys(memberTypeProperty.data.options)[0]
      default:
        return null
    }
  }

  getSelectedMemberTypeProperty = (selectedId) => {
    const { memberTypeProperties } = this.props

    return memberTypeProperties.find(memberTypeProperty => (
      memberTypeProperty.id === selectedId
    ))
  }

  propegateOnChange = (attributes) => {
    this.setState(attributes)

    attributes = { ...this.state, ...attributes }

    this.props.onChange(this.props.attributes.key, attributes)
  }

  handleSelectMemberTypeProperty = (event) => {
    const selectedId = parseInt(event.target.value)

    if (selectedId) {
      const selectedMemberTypeProperty = this.getSelectedMemberTypeProperty(selectedId)

      this.propegateOnChange({
        filterValue: this.getDefaultFilterValueForMemberTypeProperty(selectedMemberTypeProperty),
        selectedId
      })
    } else {
      this.propegateOnChange({
        selectedId: null,
        filterValue: null
      })
    }
  }

  handleChangeFilterValue = (event) => {
    this.propegateOnChange({
      filterValue: event.target.value || null
    })
  }

  handleOnRemove = (e) => {
    e.preventDefault()
    this.props.onRemove(this.props.attributes.key)
  }

  renderFilterableMemberTypePropertyOptions = () => {
    const { memberTypeProperties } = this.props
    return (
      memberTypeProperties.map(memberTypeProperty => (
        <option
          key={memberTypeProperty.id}
          value={memberTypeProperty.id}
        >
          {memberTypeProperty.name}
        </option>
      ))
    )
  }

  renderFilter = () => {
    const selectedMemberTypeProperty = this.getSelectedMemberTypeProperty(this.state.selectedId)
    let type
    if (selectedMemberTypeProperty != null) {
      type = selectedMemberTypeProperty.type
    }

    switch (type) {
      case 'MemberTypeProperties::CustomCheckbox':
        return (
          <select
            name={`report[member_type_properties][${this.state.selectedId}][]`}
            value={this.state.filterValue}
            className="form-control input-sm"
            style={{ width: 'auto', display: 'inline-block' }}
            onChange={this.handleChangeFilterValue}
          >
            <option value="t">{I18n.t('shared.yes')}</option>
            <option value="f">{I18n.t('shared.no')}</option>
          </select>
        )
      case 'MemberTypeProperties::CustomSelect':
        return (
          <select
            name={`report[member_type_properties][${this.state.selectedId}][]`}
            value={this.state.filterValue}
            className="form-control input-sm"
            style={{ width: 'auto', display: 'inline-block' }}
            onChange={this.handleChangeFilterValue}
          >
            {
              Object.entries(selectedMemberTypeProperty.data.options).map((option) => {
                const index = option[0]
                const value = option[1]
                return <option key={index} value={index}>{value[I18n.currentLocale()]}</option>
              })
            }
          </select>
        )
      default:
        return (<span />)
    }
  }

  render() {
    return (
      <div>
        <p>
          <button className="btn btn-warning pull-right" type="button" onClick={this.handleOnRemove}>{I18n.t('helpers.remove')}</button>
        </p>
        <p>
          <select
            className="form-control input-sm"
            style={{ width: 'auto', display: 'inline-block' }}
            value={this.state.selectedId || ''}
            onChange={this.handleSelectMemberTypeProperty}
          >
            <option value="">{I18n.t('choose_member_type_property', i18nOptions)}</option>
            {this.renderFilterableMemberTypePropertyOptions()}
          </select>
          {this.renderFilter()}
        </p>
      </div>
    )
  }
}

MemberTypePropertyFilterSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  attributes: PropTypes.shape({
    key: PropTypes.node.isRequired,
    memberTypePropertyId: PropTypes.number,
    value: PropTypes.node
  }).isRequired,
  memberTypeProperties: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
      deletedAt: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      required: PropTypes.bool
    }).isRequired
  ).isRequired
}

export default MemberTypePropertyFilterSelect
