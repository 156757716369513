/* global I18n */

import React, { Component } from 'react'
import ClipboardModal from './ClipboardWidget/ClipboardModal'

const i18nOptions = {
  scope: 'components.clipboard_widget'
}

class ClipboardWidget extends Component {
  state = {
    showModal: false
  }

  handleShowModal = () => {
    this.setState({ showModal: true })
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  renderModal = () => {
    if (this.state.showModal) {
      return (
        <ClipboardModal
          handleCloseModal={this.handleCloseModal}
          clipboardText={this.props.clipboardText}
          authenticationLinksPath={this.props.authenticationLinksPath}
        />
      )
    }
  }

  render() {
    return (
      <div>
        <button className='btn btn-xs btn-info m-r-xs' onClick={this.handleShowModal}>
          {I18n.t('direct_link', i18nOptions)}
        </button>
        {this.renderModal()}
      </div>
    )
  }
}

export default ClipboardWidget
