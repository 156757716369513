import { connect } from 'react-redux'
import ErrorList from '../components/ErrorList'

const mapStateToProps = state => ({
  errors: state.errors
})

const AppErrors = connect(
  mapStateToProps
)(ErrorList)

export default AppErrors
