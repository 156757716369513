import Api from '../../../Api'

class ShiftApi extends Api {
  constructor() {
    super('shifts')
  }

  select(id, { body }) {
    return this.put({
      pathPrefix: this.resourcePath(id),
      pathSuffix: 'select',
      body
    })
  }

  deselect(id) {
    return this.delete({
      pathPrefix: this.resourcePath(id),
      pathSuffix: 'select'
    })
  }

  prefer(id) {
    return this.put({
      pathPrefix: this.resourcePath(id),
      pathSuffix: 'prefer'
    })
  }

  unprefer(id) {
    return this.delete({
      pathPrefix: this.resourcePath(id),
      pathSuffix: 'prefer'
    })
  }
}

export default ShiftApi
