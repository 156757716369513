/* global I18n */

import { connect } from 'react-redux'
import { FormControl } from 'react-bootstrap'

import { setTextFilter } from '../actions/filter-actions'

const i18nOptions = {
  scope: 'shift_selector.text_filter'
}

const mapStateToProps = state => ({
  value: state.filters.textFilter,
  placeholder: I18n.t('placeholder', i18nOptions)
})

const mapDispatchToProps = dispatch => ({
  onChange: ((event) => {
    const filter = event.target.value
    dispatch(setTextFilter(filter))
  })
})

const IneligibilityFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormControl)

export default IneligibilityFilter
