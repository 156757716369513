/* global I18n */

import { Component } from 'react'
import uuid from 'uuid'
import MemberTypePropertyFilterSelect from './components/MemberTypePropertyFilterSelect'

const i18nOptions = {
  scope: 'components.shift_report_form'
}

class ShiftReportForm extends Component {
  constructor(props) {
    super(props)

    const filters = this.props.filtersParams.map(filterParams => (
      { ...filterParams, key: uuid.v4() }
    ))

    this.state = {
      filters
    }
  }

  onMemberTypePropertyFilterChange = (key, changedAttributes) => {
    const { filters } = this.state
    const { selectedId, filterValue } = changedAttributes

    this.setState({
      filters: filters.map((filter) => {
        if (filter.key === key) {
          return { ...filter, memberTypePropertyId: selectedId, value: filterValue }
        }

        return filter
      })
    })
  }

  handleAddFilter = () => {
    const { filters } = this.state

    this.setState({
      filters: filters.concat({
        key: uuid.v4(),
        memberTypePropertyId: null,
        value: null
      })
    })
  }

  handleRemoveFilter = (key) => {
    const { filters } = this.state

    this.setState({
      filters: filters.filter(filter => (
        filter.key !== key
      ))
    })
  }

  renderMemberTypePropertyFilters = () => {
    const { memberTypeProperties } = this.props
    const { filters } = this.state
    const filtersComponents = filters.map(filter => (
      <MemberTypePropertyFilterSelect
        memberTypeProperties={memberTypeProperties}
        onChange={this.onMemberTypePropertyFilterChange}
        onRemove={this.handleRemoveFilter}
        attributes={filter}
        key={filter.key}
      />
    ))

    return filtersComponents.reduce((accumulator, element) => (
      accumulator === null ? [element] : [...accumulator, <p key={uuid.v4()}>{I18n.t('shared.and')}</p>, element]
    ), null)
  }

  render() {
    return (
      <div className="form-group">
        <div>
          {this.renderMemberTypePropertyFilters()}
          <button
            className="btn btn-success"
            type="button"
            onClick={this.handleAddFilter}
          >
            {I18n.t('add_filter', i18nOptions)}
          </button>
        </div>
      </div>

    )
  }
}

ShiftReportForm.defaultProps = {
  filtersParams: []
}

ShiftReportForm.propTypes = {
  memberTypeProperties: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
      deletedAt: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      required: PropTypes.bool
    }).isRequired
  ).isRequired,
  filtersParams: PropTypes.arrayOf(
    PropTypes.shape({
      memberTypePropertyId: PropTypes.number.isRequired,
      value: PropTypes.node.isRequired
    })
  )
}

export default ShiftReportForm
