import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { preferShift, unpreferShift } from '../actions/shift-actions'
import ShiftActionButton from '../components/ShiftActionButton'

const i18nOptions = {
  scope: "shift_selector.shift"
}

const PreferShiftButton = ({ shift, preferShift, unpreferShift }) => {
  const { id, selected, eligible, ineligibilityReason, isLoading, error } = shift
  const doNothing = (id) => {}
  const handleClick =
    error ? doNothing :
    selected ? unpreferShift :
    preferShift

  return (
    <ShiftActionButton
      handleClick={() => handleClick(id)}
      buttonText={I18n.t('actions.prefer_shift', i18nOptions)}
      active={eligible || selected}
      inactiveReason={ineligibilityReason}
      error={error}
      isLoading={isLoading}
      selected={selected}
    />
  )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    preferShift,
    unpreferShift
  }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferShiftButton)
