import {
  REQUEST_SHIFTS,
  RECEIVE_SHIFTS,
  RECEIVE_SHIFT,
  SELECTING_SHIFT,
  DESELECTING_SHIFT,
  SELECTED_SHIFT,
  DESELECTED_SHIFT,
  PREFERRING_SHIFT,
  UNPREFERRING_SHIFT,
  PREFERRED_SHIFT,
  UNPREFERRED_SHIFT,
  SHIFT_SELECTION_ERROR,
  SHIFT_PREFERRING_ERROR
} from '../constants/action-types'

const updateShift = (state, updatedShift) => (
  state.map((shift) => {
    if (shift.id === updatedShift.id) {
      return { ...shift, ...updatedShift }
    }
    return shift
  })
)

const addDefaultMetaData = shift => ({
  ...shift,
  startTime: moment(shift.startTime),
  endTime: moment(shift.endTime),
  isLoading: false
})

const shiftsReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_SHIFTS:
      return action.shifts.map(shift => addDefaultMetaData(shift))
    case RECEIVE_SHIFT: {
      const newShift = addDefaultMetaData(action.shift)
      return updateShift(state, { ...newShift, error: undefined })
    }
    case SELECTING_SHIFT:
    case DESELECTING_SHIFT:
    case PREFERRING_SHIFT:
    case UNPREFERRING_SHIFT:
      return updateShift(state, { id: action.shift_id, isLoading: true })
    case SHIFT_SELECTION_ERROR:
    case SHIFT_PREFERRING_ERROR:
      return updateShift(state, { id: action.shift_id, error: action.error })
    case SELECTED_SHIFT:
    case DESELECTED_SHIFT:
    case PREFERRED_SHIFT:
    case UNPREFERRED_SHIFT:
      return updateShift(state, addDefaultMetaData(action.shift))
    default:
      return state
  }
}

export default shiftsReducer
