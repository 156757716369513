/* global I18n */

import React from 'react'

const ShiftDetailsSmall = ({ shift }) => {
  const { name, startTime, endTime, schedule, duration } = shift
  const shiftTime = `${startTime.format('Do MMM')}  ${startTime.format('HH:mm')}  ${endTime.format('HH:mm')}`
  const shiftInfo = name ? `${schedule.name}  ${name}` : schedule.name
  const shiftLocation = schedule.location

  return (
    <div className="shift-details shift-details-small">
      <div className="shiftTime">
        <p><strong>{shiftTime}</strong></p>
        <p>{I18n.t('shift_duration', { scope: 'shift_selector', count: duration })}</p>
      </div>
      <div className="shift-info">
        <p>{shiftInfo}</p>
        <p><i>{shiftLocation}</i></p>
      </div>
    </div>
  )
}

export default ShiftDetailsSmall
