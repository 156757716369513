/* global I18n */

const i18nOptions = {
  scope: 'shift_selector.shift.mtp_requirements'
}

const ShiftRequirementDetails = ({ requirements }) => {
  let requirementsText

  if (requirements.length > 0) {
    const requirementsByMtp = {}

    requirements.forEach((requirement) => {
      requirementsByMtp[requirement.id] = requirementsByMtp[requirement.id] || []
      requirementsByMtp[requirement.id].push(requirement)
    })

    requirementsText = Object.keys(requirementsByMtp).map((mtpId) => {
      const mtpRequirements = requirementsByMtp[mtpId]

      const values = mtpRequirements.map(requirement => (
        <span key={`${mtpId}${requirement.requiredValue}`}>
          {requirement.requiredPrettyValue}: {requirement.attendanceCount}/{requirement.requiredNumber}
        </span>
      ))

      return (
        <div key={mtpId}>
          <strong>{mtpRequirements[0].name}</strong>
          <p>{values}</p>
        </div>
      )
    })
  } else {
    requirementsText = I18n.t('no_requirements', i18nOptions)
  }

  return (
    <span>
      {requirementsText}
    </span>
  )
}

ShiftRequirementDetails.propTypes = {
  requirements: PropTypes.array.isRequired
}

export default ShiftRequirementDetails
