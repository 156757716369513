import MemberApi from '../apis/MemberApi'
import * as types from '../constants/action-types'

export const receiveCurrentMember = currentMember => ({
  type: types.RECEIVE_CURRENT_MEMBER,
  currentMember
})

const api = new MemberApi()

export const fetchCurrentMember = () => (dispatch) => {
  api.current()
    .then(response => response.json())
    .then(currentMember => dispatch(receiveCurrentMember(currentMember)))
}
