import React from 'react'
import { FormGroup, FormControl } from 'react-bootstrap'

class MapInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pairs: Object.entries(props.value)
    }
  }

  handleChange(event, type, index) {
    const value = event.target.value
    let pairs = this.state.pairs.slice()

    if (pairs[index] === undefined) {
      pairs[index] = ['', '']
    }

    if (type === 'key') {
      pairs[index][0] = value
    } else if (type === 'value') {
      pairs[index][1] = value
    }

    pairs = pairs.filter(([key, _]) => key !== '')

    this.setState({ pairs })
  }

  render() {
    const { inputProps, labelProps } = this.props
    const pairs = this.state.pairs.slice()
    const keys = pairs.map(([key, _]) => key)

    pairs.push(['', ''])

    return (
      <FormGroup>
        { pairs.map(([key, value], index) => {
          const hasName = (key !== '' && inputProps.name !== undefined)
          const namePrefix = `${inputProps.name}[${index}]`

          return (
            <FormGroup
              key={index}
              className="form-inline"
              validationState={[...keys.slice(0, index), ...keys.slice(index + 1)].includes(key) ? 'error' : null}
            >

              <FormControl
                {...inputProps}
                value={key}
                key={`key-${index}`}
                onChange={e => this.handleChange(e, 'key', index)}
                placeholder="Key"
                name={hasName ? `${namePrefix}[key]` : null}
              />
              <FormControl
                {...inputProps}
                value={value}
                disabled={key === ''}
                key={`value-${index}`}
                onChange={e => this.handleChange(e, 'value', index)}
                placeholder="Value"
                name={hasName ? `${namePrefix}[value]` : null}
              />
            </FormGroup>
          )
        })}
      </FormGroup>
    )
  }
}

MapInput.defaultProps = {
  inputProps: {},
  value: {}
}

MapInput.propTypes = {
  inputProps: PropTypes.object,
  value: PropTypes.object
}

export default MapInput
