/* global I18n */

import TextFilter from '../containers/TextFilter'
import IneligibilityFilter from '../containers/IneligibilityFilter'
import AvailableShiftsView from '../containers/AvailableShiftsView'
import ViewSelector from '../containers/ViewSelector'

const i18nOptions = {
  scope: 'shift_selector'
}

const MainView = () => {

  return (
    <div className="ibox">
      <div className="ibox-title">
        <h5>{I18n.t('available_shifts_header', i18nOptions)}</h5>
        <div className="ibox-tools">
          <ViewSelector />
        </div>
      </div>
      <div className="ibox-content">
        <div className="form-group">
          <TextFilter />
        </div>
        <div className="form-group">
          <IneligibilityFilter />
        </div>
      </div>
      <div className="ibox-content">
        <AvailableShiftsView />
      </div>
    </div>
  )
}

export default MainView
