import React from 'react'
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'react-bootstrap'
import { setCurrentView } from '../actions/ui-actions'

const ViewSelector = ({ currentView, setCurrentView }) => (
  <ButtonGroup>
    <Button
      bsSize="xs"
      bsStyle="white"
      active={currentView === 'list-grouped-by-date'}
      onClick={() => setCurrentView('list-grouped-by-date')}
    >
      <i className="fa fa-list" />
    </Button>
    <Button
      bsSize="xs"
      bsStyle="white"
      active={currentView === 'list-grouped-by-schedule'}
      onClick={() => setCurrentView('list-grouped-by-schedule')}
    >
      <i className="fa fa-address-book" />
    </Button>
  </ButtonGroup>
)

const mapStateToProps = state => ({
  currentView: state.ui.currentView
})

export default connect(
  mapStateToProps,
  {
    setCurrentView
  }
)(ViewSelector)
