/* global I18n */

import { Component } from 'react'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import { Nav, NavItem, Modal } from 'react-bootstrap'

import AppErrors from './AppErrors'
import { fetchShifts, selectShift } from '../actions/shift-actions'
import { fetchCurrentMember } from '../actions/current-member-actions'
import { setCurrentTab } from '../actions/ui-actions'
import Badge from '../components/Badge'
import Sidebar from '../components/Sidebar'
import MainView from '../components/MainView'
import RequirementSelector from '../components/RequirementSelector'

class ScheduleApp extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userShiftsCount: PropTypes.number.isRequired
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchShifts())
    dispatch(fetchCurrentMember())
  }

  render() {
    const i18nOptions = {
      scope: 'shift_selector'
    }

    const {
      dispatch,
      currentTab,
      setCurrentTab,
      isSelectingRequirement,
      selectingRequirementsForShift,
      requirementsForSelection,
      noRequirementOption,
      onRequirementSelect,
      isLoading,
      minShiftHours,
      maxShiftHours
    } = this.props

    const handleTabClick = (tab) => {
      dispatch(setCurrentTab(tab))
    }

    if (isLoading) {
      return (
        <div className="loading_container"><i className="fa fa-spinner fa-spin" /></div>
      )
    }

    return (
      <div className="row schedule-app">
        <div className="col-md-12">
          <AppErrors />
        </div>

        {/* Mobile layout: */}
        <MediaQuery query="(max-width: 1280px)">
          <div className="mobile-view">
            <div className="col-xs-12">
              <div className="tabs-container">
                <Nav bsStyle="tabs" activeKey={currentTab} onSelect={handleTabClick}>
                  <NavItem eventKey="available-shifts">{I18n.t('available_shifts_header', i18nOptions)}</NavItem>
                  <NavItem eventKey="your-shifts">
                    {I18n.t('sidebar_header', i18nOptions)}
                    &nbsp;
                    <Badge content={this.props.userShiftsCount} />
                  </NavItem>
                </Nav>
              </div>
            </div>
            <div className="col-xs-12">
              { currentTab === 'available-shifts' ? <MainView /> : <Sidebar minShiftHours={minShiftHours} maxShiftHours={maxShiftHours} /> }
            </div>
          </div>
        </MediaQuery>

        {/* Desktop layout: */}
        <MediaQuery query="(min-width: 1280px)">
          <div className="desktop-view">
            <div className="col-md-8">
              <MainView />
            </div>
            <div className="col-md-4">
              <Sidebar minShiftHours={minShiftHours} maxShiftHours={maxShiftHours} />
            </div>
          </div>
        </MediaQuery>

        <Modal backdrop="static" show={isSelectingRequirement}>
          <Modal.Header>
            <Modal.Title>{I18n.t('requirement_selector.header', i18nOptions)}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            { selectingRequirementsForShift ?
              <RequirementSelector
                shiftId={selectingRequirementsForShift}
                requirements={requirementsForSelection}
                noRequirementOption={noRequirementOption}
                onRequirementSelect={onRequirementSelect}
              />
              : null
            }

          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.ui,
  minShiftHours: state.currentMember.minShiftHours,
  maxShiftHours: state.currentMember.maxShiftHours,
  userShiftsCount: state.shifts.filter(shift => shift.preferred || shift.selected ).length
})

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    setCurrentTab,
    onRequirementSelect: (shiftId, requirementId) => {
      dispatch(selectShift(shiftId, requirementId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleApp)
