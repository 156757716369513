import { createSelector } from 'reselect'
import getVisibleShifts from './getVisibleShifts'

const getFilter = state => state.filters.textFilter

const getDatumForShift = (shift) => {
  let datum = ''
  if (shift.name) { datum += shift.name }
  if (shift.schedule.location) { datum += shift.schedule.location }
  if (shift.schedule.name) { datum += shift.schedule.name }
  return datum
}

const getFilteredShifts = createSelector(
  getVisibleShifts, getFilter,
  (shifts, filter) => {
    const regex = new RegExp(filter, 'i')

    return shifts.filter((shift) => {
      const datum = getDatumForShift(shift)
      return datum.search(regex) > -1
    })
  }
)

export default getFilteredShifts
