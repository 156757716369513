import ShiftApi from '../apis/ShiftApi'
import { unknownError } from './error-actions'

import * as types from '../constants/action-types'

import {
  selectingRequirement,
  selectedRequirement
} from '../actions/ui-actions'

export const requestShifts = () => ({ type: types.REQUEST_SHIFTS })
export const receiveShifts = shifts => ({ type: types.RECEIVE_SHIFTS, shifts })
export const receiveShift = shift => ({ type: types.RECEIVE_SHIFT, shift })
export const selectingShift = shiftId => ({ type: types.SELECTING_SHIFT, shiftId })
export const deselectingShift = shiftId => ({ type: types.DESELECTING_SHIFT, shiftId })
export const selectedShift = shift => ({ type: types.SELECTED_SHIFT, shift })
export const deselectedShift = shift => ({ type: types.DESELECTED_SHIFT, shift })
export const preferringShift = shiftId => ({ type: types.PREFERRING_SHIFT, shiftId })
export const unpreferringShift = shiftId => ({ type: types.UNPREFERRING_SHIFT, shiftId })
export const preferredShift = shift => ({ type: types.PREFERRED_SHIFT, shift })
export const unpreferredShift = shift => ({ type: types.UNPREFERRED_SHIFT, shift })

export const shiftSelectionError = (shiftId, error) => ({
  type: types.SHIFT_SELECTION_ERROR,
  shiftId,
  error
})

export const shiftPreferringError = (shiftId, error) => ({
  type: types.SHIFT_PREFERRING_ERROR,
  shiftId,
  error
})

const api = new ShiftApi()

export const fetchShifts = () => (dispatch) => {
  // dispatch(requestShifts()) TODO: IMPLEMENT

  api.fetchAll()
    .then(response => response.json())
    .then(shifts => dispatch(receiveShifts(shifts)))
}

export const fetchShift = shiftId => (dispatch) => {
  api.fetch(shiftId)
    .then((response) => {
      if (response.status === 200) {
        response.json()
        .then(shift => dispatch(receiveShift(shift)))
      }
    })
}

export const selectShift = (shiftId, requirementId = null) => (dispatch) => {
  dispatch(selectedRequirement())
  dispatch(selectingShift(shiftId))
  return api.select(shiftId, {
    body: { requirement_id: requirementId }
  })
    .then((response) => {
      if (response.status === 200) {
        response.json().then(shift => dispatch(selectedShift(shift)))
      } else if (response.status === 422) {
        response.text().then((error) => {
          dispatch(shiftSelectionError(shiftId, error))
          setTimeout(() => dispatch(fetchShift(shiftId)), 2000)
        })
      } else {
        response.text().then(_ => dispatch(unknownError()))
      }
    })
}

export const checkRequirementsAndSelectShift = shiftId => (dispatch, getState) => {
  const state = getState()
  const shift = getShift(shiftId, state)

  if (shift.memberTypePropertyRequirements.length === 0) {
    dispatch(selectShift(shiftId))
  } else {
    const currentMember = state.currentMember

    const matchingRequirements = shift.memberTypePropertyRequirements.filter((mtpRequirement) => {
      if (mtpRequirement.attendanceCount < mtpRequirement.requiredNumber) {
        const matchingPropertyValue = currentMember.propertyValues.find((mtpValue) => {
          if (mtpRequirement.memberTypePropertyId !== mtpValue.propertyId) {
            return false
          }

          if (Array.isArray(mtpValue.value)) {
            return mtpValue.value.includes(parseInt(mtpRequirement.requiredValue))
          }
          return `${mtpValue.value}` === mtpRequirement.requiredValue
        })
        return matchingPropertyValue !== undefined
      }
      return false
    })

    const spotsWithRequirements =
      shift.memberTypePropertyRequirements
      .map(mtpRequirement => mtpRequirement.requiredNumber - mtpRequirement.attendanceCount)
      .reduce((a, b) => a + b, 0)
    const spotsWithoutRequirement = shift.availableSpots - spotsWithRequirements

    if (spotsWithoutRequirement > 0 && matchingRequirements.length === 0) {
      dispatch(selectShift(shiftId))
    } else if (spotsWithoutRequirement === 0 && matchingRequirements.length === 1) {
      dispatch(selectShift(shiftId, matchingRequirements[0].id))
    } else {
      const noRequirementOption = spotsWithoutRequirement > 0
      dispatch(selectingRequirement(shiftId, matchingRequirements, noRequirementOption))
    }
  }
}

export const deselectShift = shiftId => (dispatch) => {
  dispatch(deselectingShift(shiftId))
  return api.deselect(shiftId)
    .then(response => response.json())
    .then(shift => dispatch(deselectedShift(shift)))
}

export const preferShift = shiftId => (dispatch) => {
  dispatch(preferringShift(shiftId))
  return api.prefer(shiftId)
    .then((response) => {
      if (response.status === 200) {
        response.json().then(shift => dispatch(preferredShift(shift)))
      } else if (response.status === 422) {
        response.text().then((error) => {
          dispatch(shiftPreferringError(shiftId, error))
          setTimeout(() => dispatch(fetchShift(shiftId)), 2000)
        })
      } else {
        response.text().then(_ => dispatch(unknownError()))
      }
    })
}

export const unpreferShift = shiftId => (dispatch) => {
  dispatch(unpreferringShift(shiftId))
  return api.unprefer(shiftId)
    .then(response => response.json())
    .then(shift => dispatch(unpreferredShift(shift)))
}

const getShift = (shiftId, state) => (
  state.shifts.find(shift => shift.id === shiftId)
)
