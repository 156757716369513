import React from 'react'

const ShiftList = ({ header, children }) => {
  const shiftHeader = header ? (
    <div className="shift-list-header">
      <strong>{header}</strong>
    </div>
  ) : ''

  return (
    <div className="shift-list">
      { shiftHeader }
      { children }
    </div>
  )
}

export default ShiftList
