import React from 'react'
import { connect } from 'react-redux'
import getGroupedShifts from '../selectors/getGroupedShifts'
import Shift from '../components/Shift'
import ShiftList from '../components/ShiftList'
import ShiftDetailsMedium from '../components/ShiftDetailsMedium'
import ShiftDetailsOneLine from '../components/ShiftDetailsOneLine'

const AvailableShiftsView = ({ groupedShifts, currentView }) => {
  const shiftListHeader = key => (
    currentView === 'list-grouped-by-date' ? moment(key).format('dddd [d.] Do MMMM YYYY') : key
  )

  let ShiftDetails
  switch (currentView) {
    case 'list-grouped-by-date':
      ShiftDetails = ShiftDetailsMedium
      break
    case 'list-grouped-by-schedule':
      ShiftDetails = ShiftDetailsOneLine
      break
    default:
      throw new Error(`Unknown view: ${currentView}`)
  }

  const renderShiftGroup = group => group.map(shift => (
    <Shift
      key={shift.id}
      size={currentView === 'list-grouped-by-date' ? 'medium' : 'one-line'}
      shift={shift}
    >
      <ShiftDetails shift={shift} />
    </Shift>
  ))

  return (
    <div>
      {Object.entries(groupedShifts).map(([key, group]) => (
        <ShiftList
          key={key}
          header={shiftListHeader(key)}
        >
          {renderShiftGroup(group)}
        </ShiftList>
      ))}
    </div>
  )
}

const mapStateToProps = state => ({
  groupedShifts: getGroupedShifts(state),
  currentView: state.ui.currentView
})

export default connect(
  mapStateToProps
)(AvailableShiftsView)
