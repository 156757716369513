const Badge = ({ content }) => (
  <span className={'badge'}>
    {content}
  </span>
)

Badge.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
}

export default Badge
