/* global I18n */

import { createSelector } from 'reselect'

const getShifts = state => state.shifts
const getCurrentMember = state => state.currentMember

const hasAvailableSpots = shift => (
  shift.availableSpots > 0
)

const memberHasEnoughAvailableHours = (shift, currentMember, totalSelectedHours) => {
  if (currentMember.maxShiftHours === null) { return true }

  return totalSelectedHours + shift.duration <= currentMember.maxShiftHours
}

const getShiftsWithEligibility = createSelector(
  getShifts,
  getCurrentMember,
  (shifts, currentMember) => {
    const directSelectableShifts = shifts.filter(shift => shift.selectionType === 'direct')
    const directlySelectedShifts = directSelectableShifts.filter(shift => shift.selected)
    const shiftDurations = directlySelectedShifts.map(shift => shift.duration)
    const totalDirectlySelectedHours = shiftDurations.reduce((a, b) => a + b, 0)

    return shifts.map((shift) => {
      if (!hasAvailableSpots(shift)) {
        return ({
          ...shift,
          eligible: false,
          ineligibilityReason: I18n.t('shift_selector.shift.no_available_spots')
        })
      } else if (shift.selectionType === 'direct' && !memberHasEnoughAvailableHours(shift, currentMember, totalDirectlySelectedHours)) {
        return ({
          ...shift,
          eligible: false,
          ineligibilityReason: I18n.t('shift_selector.shift.not_enough_hours_left')
        })
      }
      return ({
        ...shift,
        eligible: true,
        ineligibilityReason: null
      })
    })
  }
)

export default getShiftsWithEligibility
