import Alert from './Alert'

const ErrorList = ({ errors }) => {
  let i = 0

  return (
    <div>
      {errors.map((error) => {
        i += 1

        return (
          <Alert
            key={i}
            {...error}
          />
        )
      })}
    </div>
  )
}

ErrorList.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default ErrorList
