/* global I18n */

import { connect } from 'react-redux'
import Badge from '../components/Badge'

const i18nOptions = {
  scope: 'shift_selector'
}

const mapStateToProps = (state) => {
  const shiftHours = state.shifts
    .filter(shift => shift.preferred)
    .map(shift => shift.duration)
    .reduce((a, b) => (a + b), 0)

  return {
    content: I18n.t('shift_duration', { ...i18nOptions, count: shiftHours })
  }
}

const PreferredShiftsBadge = connect(
  mapStateToProps
)(Badge)

export default PreferredShiftsBadge
