// Shifts
export const REQUEST_SHIFTS = 'REQUEST_SHIFTS'
export const RECEIVE_SHIFTS = 'RECEIVE_SHIFTS'
export const RECEIVE_SHIFT = 'RECEIVE_SHIFT'
export const SELECTED_SHIFT = 'SELECTED_SHIFT'
export const DESELECTED_SHIFT = 'DESELECTED_SHIFT'
export const SELECTING_SHIFT = 'SELECTING_SHIFT'
export const DESELECTING_SHIFT = 'DESELECTING_SHIFT'
export const PREFERRED_SHIFT = 'PREFERRED_SHIFT'
export const UNPREFERRED_SHIFT = 'UNPREFERRED_SHIFT'
export const PREFERRING_SHIFT = 'PREFERRING_SHIFT'
export const UNPREFERRING_SHIFT = 'UNPREFERRING_SHIFT'
export const SHIFT_SELECTION_ERROR = 'SHIFT_SELECTION_ERROR'
export const SHIFT_PREFERRING_ERROR = 'SHIFT_PREFERRING_ERROR'

// Errors
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR'

// Current Member
export const RECEIVE_CURRENT_MEMBER = 'RECEIVE_CURRENT_MEMBER'

// Filters
export const SET_INELIGIBILITY_FILTER = 'SET_INELIGIBILITY_FILTER'
export const SET_TEXT_FILTER = 'SET_TEXT_FILTER'

// UI
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW'
export const SELECTING_REQUIREMENT = 'SELECTING_REQUIREMENT'
export const SELECTED_REQUIREMENT = 'SELECTED_REQUIREMENT'
