import Api from '../../../Api'

class MemberApi extends Api {
  constructor() {
    super('members')
  }

  current() {
    return this.get({
      pathPrefix: this.resource,
      pathSuffix: 'me'
    })
  }
}

export default MemberApi
