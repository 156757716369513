import {
  SET_INELIGIBILITY_FILTER,
  SET_TEXT_FILTER
} from '../constants/action-types'

const defaultState = {
  ineligibilityFilter: 'HIDE_INELIGIBLE',
  textFilter: ''
}

const filtersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_INELIGIBILITY_FILTER:
      return {
        ...state,
        ineligibilityFilter: action.filter
      }
    case SET_TEXT_FILTER:
      return {
        ...state,
        textFilter: action.filter
      }
    default:
      return state
  }
}

export default filtersReducer
