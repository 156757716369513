/* global I18n */

import React, { Component } from 'react'
import { Modal, Button, FormGroup, InputGroup, FormControl, Tooltip } from 'react-bootstrap'
import Clipboard from 'clipboard'
import fetch from 'isomorphic-fetch'

const i18nOptions = {
  scope: 'components.clipboard_widget'
}

class ClipboardModal extends Component {
  state = {
    showTooltip: false,
    sendingLink: false
  }

  componentDidMount() {
    this.clipboard = new Clipboard(this.clipboardButtonRef)
    this.clipboard.on('success', () => {
      this.setState({ showTooltip: true })
    })
  }

  componentWillUnmount() {
    this.clipboard.destroy()
  }

  tooltip = () => {
    if (this.state.showTooltip) {
      return (
        <Tooltip placement="bottom" className="in" id="clipboard-tooltip-copied">
          {I18n.t('copied', i18nOptions)}
        </Tooltip>
      )
    }
  }

  handleHideTooltip = () => {
    this.setState({ showTooltip: false })
  }

  handleSendLink = () => {
    this.setState({ sendingLink: true })

    fetch(this.props.authenticationLinksPath, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).then(() => {
      this.setState({ sendingLink: false })
    })
  }

  render() {
    return (
      <Modal show={true} onHide={this.props.handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('direct_link', i18nOptions)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <InputGroup>
              <FormControl
                id="clipboard-text"
                type="text"
                value={this.props.clipboardText}
                readOnly
              />
              <InputGroup.Button>
                <button
                  className="btn btn-white"
                  data-clipboard-target="#clipboard-text"
                  ref={button => (this.clipboardButtonRef = button)}
                  onMouseLeave={this.handleHideTooltip}
                >
                  <i className="fa fa-clipboard" aria-hidden="true"></i>
                </button>
                {this.tooltip()}
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
          <Button
            bsStyle="primary"
            disabled={this.state.sendingLink}
            onClick={this.state.sendingLink ? null : this.handleSendLink}
          >
            {this.state.sendingLink ? I18n.t('sending_link', i18nOptions) : I18n.t('send_link_to_owner', i18nOptions)}
          </Button>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ClipboardModal
