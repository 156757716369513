import {
  UNKNOWN_ERROR
} from '../constants/action-types'

const errorsReducer = (state = [], action) => {
  switch (action.type) {
    case UNKNOWN_ERROR:
      return [
        ...state,
        { message: 'Unkonwn Error' }
      ]
    default:
      return state
  }
}

export default errorsReducer
