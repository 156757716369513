import {
  SET_CURRENT_TAB,
  SET_CURRENT_VIEW,
  SELECTING_REQUIREMENT,
  SELECTED_REQUIREMENT,
  RECEIVE_SHIFTS
} from '../constants/action-types'

const defaultState = {
  currentTab: 'available-shifts',
  currentView: 'list-grouped-by-date',
  isSelectingRequirement: false,
  selectingRequirementsForShift: null,
  requirementsForSelection: null,
  noRequirementOption: false,
  isLoading: true
}

const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.tab
      }
    case SELECTING_REQUIREMENT:
      return {
        ...state,
        isSelectingRequirement: true,
        requirementsForSelection: action.requirements,
        selectingRequirementsForShift: action.shiftId,
        noRequirementOption: action.noRequirementOption
      }
    case SELECTED_REQUIREMENT:
      return {
        ...state,
        isSelectingRequirement: false,
        selectingRequirementsForShift: null,
        requirementsForSelection: null,
        noRequirementOption: false
      }
    case RECEIVE_SHIFTS:
      return {
        ...state,
        isLoading: false
      }
    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: action.view
      }
    default:
      return state
  }
}

export default uiReducer
